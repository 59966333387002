import React from 'react'
import './Account.scss'
import usePersonalNotes from '../../hooks/usePersonalNotes'
import { RootContext } from '../../state/RootContext'
import TextInput from '../../components/TextInput'
import canonicalize from '../../state/canonicalize'
import Modal from '../../components/Modal'

const Account = () => {
  const { state } = React.useContext(RootContext)
  const { user } = state
  const { data: notes, onSave: saveNotes } = usePersonalNotes(user)
  const [edited, reduceEdited] = React.useReducer((prev: any, next: any) => ({ ...prev, ...next }), {})
  const hasChanged = React.useMemo(() => canonicalize(notes) !== canonicalize(edited), [notes, edited])
  const onSave = React.useCallback(() => saveNotes(edited), [edited, saveNotes])

  React.useEffect(() => {
    if (notes) {
      reduceEdited(notes)
    }
  }, [notes])

  // TODO: store these in the notes object and use them to render
  // const fields = ['custom','pickup','scam','stockist_pricing','towers']
  const [field, setField] = React.useState<string>()
  const onAddField = React.useCallback(() => setField(''), [])
  const onCancelField = React.useCallback(() => setField(undefined), [])
  const onSaveNewField = React.useCallback(() => {
    if (notes) {
      const key = field?.replace(/\s/g, '_').toLowerCase()

      saveNotes({ _fields: [...notes._fields, key] })
        .then(() => setField(undefined))
    }
  }, [notes, saveNotes, field])

  return (
    <div className="Account">
      <h1>Account</h1>
      <div className="NotesContainer">
        {notes && (
          <>

            {typeof field === 'string' && (
              <Modal onClose={onCancelField}>
                <TextInput value={field} onChange={setField} label="Field Name" />
                <button type="button" className="button" onClick={onSaveNewField}>Add Note</button>
              </Modal>
            )}

            <div className="ActionBar">
              <button className="button" disabled={!hasChanged} onClick={onSave}>Save Changes {hasChanged && '(Edited)'}</button>
            </div>

            <div className="Notes">
              {notes._fields.sort().map(fieldName => (
                <div key={fieldName} className="Note">
                  <label>{fieldName}</label>
                  <TextInput textarea value={edited[fieldName] || ''} onChange={val => reduceEdited({ [fieldName]: val })} />
                </div>
              ))}
            </div>

            <div className="Tags">
              <button type="button" className="button" onClick={onAddField}>Add New Note</button>
              {notes._fields.map(it => <span key={it} className="Field Tag">{it}</span>)}
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default Account
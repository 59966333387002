import React from 'react'
import './ErrorBox.scss'

type ErrorBoxProps = {
  error: Error
}

const ErrorBox: React.FC<ErrorBoxProps> = ({ error }) => {
  return (
    <div className="ErrorBox">
      {error.message}
    </div>
  )
}

export default ErrorBox
import React from 'react'
type useLocalStorageFunc = <T>(key: string, defaultValue: T) => [
  T,
  (value: T|prevValueSetterFunction) => void
]

type prevValueSetterFunction = (prev: any) => any

const defaultOrLocalStorage = (key: string, defaultValue: any) => {
  const cache = localStorage.getItem(key)
  return cache ? cache : JSON.stringify(defaultValue)
}
const useLocalStorage: useLocalStorageFunc = (key, defaultValue) => {
  const [value, setValue] = React.useState<string>(defaultOrLocalStorage(key, defaultValue))

  const parsed = React.useMemo(() => {
    try {
      return JSON.parse(value)
    } catch (err) {
      return JSON.stringify('')
    }
  }, [value])

  const valueSetter = React.useCallback((newValueOrFunction: any | prevValueSetterFunction) => {
    let stringifyValue = ''

    if (typeof newValueOrFunction === 'function') {
      stringifyValue = JSON.stringify(newValueOrFunction(JSON.parse(value)))
    } else {
      stringifyValue = JSON.stringify(newValueOrFunction)
    }
    
    setValue(stringifyValue)
    localStorage.setItem(key, stringifyValue)
  }, [key, value, setValue])

  return [
    parsed,
    valueSetter,
  ]
}

export default useLocalStorage
import React from 'react'
import Modal from '../../components/Modal'
import useMacOrders, { MacOrder, createBlankOrder } from '../../hooks/useMacOrders'
import { RootContext } from '../../state/RootContext'
import moment from 'moment'
import './Orders.scss'
import MacOrderForm from './MacOrderForm'
import MacOrderRow from './MacOrderRow'
import TextInput from '../../components/TextInput'
import DatePickerInput from '../../components/DatePickerInput'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Link,
  RouteProps,
} from 'react-router-dom'
import GeneralRoute from '../../components/GeneralRoute'
import * as uuid from 'uuid'
import useLocalStorage from '../../hooks/useLocalStorage'

const filterFinished = (a: MacOrder) => {
  return a.nextAction === 'none'
}

const filterNotFinished = (a: MacOrder) => {
  return a.nextAction !== 'none'
}

// Only returns orders in the past
// const filterPast = (a: MacOrder) => {
//   return moment(a.fulfillAt).isBefore()
// }

// Only returns orders in the future
// const filterFuture = (a: MacOrder) => {
//   return moment(a.fulfillAt).isAfter()
// }

const sortByDate = (a: MacOrder, b: MacOrder) => {
  if (moment(b.fulfillAt).diff(moment(a.fulfillAt)) === 0) {
    return a.customerName.localeCompare(b.customerName)
  }

  return moment(b.fulfillAt).diff(moment(a.fulfillAt))
}

const sortByDateAsc = (a: MacOrder, b: MacOrder) => {
  if (moment(a.fulfillAt).diff(moment(b.fulfillAt)) === 0) {
    return a.customerName.localeCompare(b.customerName)
  }

  return moment(a.fulfillAt).diff(moment(b.fulfillAt))
}

const HeaderRow = () => (
  <div className="MacOrderRow Heading">
    <span className="Status">Status</span>
    <span className="CustomerName">Customer</span>
    <span className="Description">Description</span>
    <span className="Source">Sources</span>
    <span className="PickUpDate">Fufill At</span>
  </div>
)

const Orders: React.FC<{ orders?: MacOrder[] }> = (props) => {
  const [search, setSearch] = useLocalStorage('Orders-search', '')
  const { orders } = props

  const finishedOrders = React.useMemo(() => orders && orders.filter(filterFinished), [orders])
  const sortedFinishedOrders = React.useMemo(() => finishedOrders && finishedOrders.sort(sortByDate), [finishedOrders])

  const activeOrders = React.useMemo(() => orders && orders.filter(filterNotFinished), [orders])
  const sortedActiveOrders = React.useMemo(() => activeOrders && activeOrders.sort(sortByDateAsc), [activeOrders])

  const searchResultsActive = React.useMemo(() => {
    if (search.trim() === '') {
      return []
    }

    return (activeOrders || [])
      .filter(it => it.filterText.includes(search.trim().toLowerCase()))
      .sort(sortByDateAsc)
  }, [search, activeOrders])

  const searchResultsFinished = React.useMemo(() => {
    if (search.trim() === '') {
      return []
    }

    return (finishedOrders || [])
      .filter(it => it.filterText.includes(search.trim().toLowerCase()))
      .sort(sortByDate)
  }, [search, finishedOrders])

  // const onNew = React.useCallback(() => setMacOrder(createBlankOrder()), [])
  // const onClose = React.useCallback(() => setMacOrder(undefined), [])

  const selectedDate = React.useMemo(() => {
    if (search && search.length > 0) {
      try {
        if (moment(search, 'L').isValid()) {
          return moment(search, 'L')
        }
      } catch (error) {

      }
    }

    return moment()
  }, [search])

  return (
    <div className="Orders">
      <h1>Orders</h1>

      <div className="ActionContainer">
        <Link className="button" to={`/orders/${uuid.v4()}`}>New Order</Link>
      </div>

      <div className="ActionContainer">
        <DatePickerInput selectedDate={selectedDate} onChange={date => setSearch(date.format('LL'))} />
        <TextInput className={search ? 'Searching' : ''} placeholder="Search" value={search} onChange={setSearch} />
        <button className="button secondary" type="button" onClick={() => setSearch('')}>X</button>
      </div>

      <div className="MacOrdersContainer">
        {search.trim().length > 0 && (
          <>
            <h4>Search Results (Active Orders)</h4>
            <HeaderRow />
            {searchResultsActive.map(it => (
              <Link key={it.uid} to={`/orders/${it.uid}`}>
                <MacOrderRow {...it} />
              </Link>
            ))}
            <h4>Search Results (Finished Orders)</h4>
            <HeaderRow />
            {searchResultsFinished.map(it => (
              <Link key={it.uid} to={`/orders/${it.uid}`}>
                <MacOrderRow {...it} />
              </Link>
            ))}
          </>
        )}

        {search.trim().length === 0 && (
          <>
            <h4>Active</h4>
            <HeaderRow />
            {sortedActiveOrders && sortedActiveOrders.map(it => (
              <Link key={it.uid} to={`/orders/${it.uid}`}>
                <MacOrderRow {...it} />
              </Link>
            ))}
            <h4>Completed / Cancelled</h4>
            <HeaderRow />
            {sortedFinishedOrders && sortedFinishedOrders.map(it => (
              <Link key={it.uid} to={`/orders/${it.uid}`}>
                <MacOrderRow {...it} />
              </Link>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

const OrdersRouter = () => {
  const { state } = React.useContext(RootContext)
  const { user } = state
  const match = useRouteMatch()
  const orders = useMacOrders(user)

  return (
    <Router>
      <Switch>
        <GeneralRoute path={`${match.path}/`} exact>
          <Orders orders={orders} />
        </GeneralRoute>
        <GeneralRoute path={`${match.path}/:orderId`} exact>
          {orders && <MacOrderForm orders={orders} />}
        </GeneralRoute>
      </Switch>
    </Router>
  )
}

export default OrdersRouter
import React from 'react'
import firebase from 'firebase'
import { cloud } from '../state/config'

type ShopifyShippingLines = {
  id: number
  title: string
  price: string
  code: string
  source: "shopify"
  discounted_price: string
}

export type ShopifyLineItems = {
  id: number
  variant_id: number
  title: string
  quantity: number
  sku: string
  product_id: number
  name: string
  fulfillable_quantity: number
  price: string
}

export type ShopifyOrder = {
  id: number
  email: string
  created_at: string
  updated_at: string
  total_price: string
  subtotal_price: string
  confirmed: boolean
  total_line_items_price: string
  cancelled_at: string
  order_number: number
  contact_email: string
  order_status_url: string,
  line_items: ShopifyLineItems[],
  shipping_lines: ShopifyShippingLines[]
  billing_address: {
    first_name: string | null
    address1: string | null
    phone: string | null
    city: string | null
    zip: string | null
    province: string | null
    country: string | null
    last_name: string | null
    address2: string | null
    company: string | null
    latitude: string | null
    longitude: string | null
    name: string | null
    country_code: string | null
    province_code: string | null
  },
  shipping_address: {
    first_name: string | null
    address1: string | null
    phone: string | null
    city: string | null
    zip: string | null
    province: string | null
    country: string | null
    last_name: string | null
    address2: string | null
    company: string | null
    latitude: string | null
    longitude: string | null
    name: string | null
    country_code: string | null
    province_code: string | null
  },
  customer: {
    id: number
    email: string
    first_name: string
    last_name: string
    orders_count: number
    phone: string | null
    default_address: {
      id: number
      customer_id: number
      first_name: string | null
      last_name: string | null
      company: string | null
      address1: string | null
      address2: string | null
      city: string | null
      province: string | null
      country: string | null
      zip: string | null
      phone: string | null
      name: string | null
      province_code: string | null
      country_code: string | null
      country_name: string | null
      default: boolean
    }
  }

  uid: string
  filterText?: string
}

export const generateFilterText = (order: ShopifyOrder) => {
  return ``
}

const useFirestoreShopifyOrders = (user?: firebase.User): ShopifyOrder[] | undefined => {
  const [items, setItems] = React.useState<ShopifyOrder[]>()

  React.useEffect(() => {
    if (user) {
      return cloud
        .firestore()
        .collection('shopify_orders')
        .onSnapshot((snap) => {
          const updates: ShopifyOrder[] = []
          const deletions: string[] = []
          
          snap.docChanges().forEach(change => {
            deletions.push(change.doc.id)

            if (change.type !== 'removed') {
              deletions.push(change.doc.id)
              const { customer, email, customerDefaultAddress, line_items, shipping_lines, ...data } = change.doc.data() as any

              updates.push({
                ...data,
                email: email.toLowerCase(),
                line_items: typeof line_items === 'string' ? JSON.parse(line_items) : [],
                shipping_lines: typeof shipping_lines === 'string' ? JSON.parse(shipping_lines) : [],
                customer: {
                  ...customer,
                  default_address: customerDefaultAddress,
                },
                filterText: generateFilterText(data),
                uid: change.doc.id,
              })
            }
          })

          setItems(prev => {
            if (prev) {
              return [
                ...prev.filter(it => !deletions.includes(it.uid)),
                ...updates,
              ]
            }

            return updates
          })
        })
    }
  }, [user])

  return items
}

export default useFirestoreShopifyOrders
import React from 'react'

import logo from '../assets/logo.svg'

const ratio = 1

interface Props {
  height: number
  collapseWidth?: boolean
}

const Logo: React.FC<Props> = ({ height, collapseWidth }) => {
  return (
    <img src={logo} height={height} width={height * ratio} alt="" style={collapseWidth ? { maxWidth: '100%' } : undefined} />
  )
}

export default Logo
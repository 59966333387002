import React from 'react'
import { cloud } from '../state/config'
import { createClient, Provider, Client } from 'urql'

type ShopifySettings = {
  api_key: string
  password: string
  url: string
}

const useShopifyCredentials = (user?: firebase.User) => {
  const [settings, setSettings] = React.useState<ShopifySettings>()

  React.useEffect(() => {
    if (user) {
      cloud
        .firestore()
        .collection('secrets')
        .doc('shopify')
        .get()
        .then(snap => setSettings(snap.data() as ShopifySettings))
    }
  }, [user])
  
  return settings
}

export const useShopifyGraphQLClient = (user?: firebase.User) => {
  const settings = useShopifyCredentials(user)
  const [client, setClient] = React.useState<Client>(createClient({ url: 'http://localhost' }))

  React.useEffect(() => {
    if (settings) {
      const { api_key, url } = settings
      setClient(createClient({
        url,
        fetchOptions: () => ({ headers: { 'X-Shopify-Access-Token': api_key }}),
      }))
    }
  }, [settings])

  return {
    client,
  }
}
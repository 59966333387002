const CustomersQuery = `
query($id: ID!, $page: Int!, $pageSize: Int!) {
  business (id: $id, ) {
    customers (page: $page, pageSize: $pageSize, sort: CREATED_AT_DESC) {
      pageInfo {
        currentPage
        totalPages
      }
      edges {
        node {
          id
          name
          firstName
          lastName
          email
          mobile
          shippingDetails {
            name
            address {
              addressLine1
              addressLine2
              city
            }
            phone
            instructions
          }
        }
      }
    }
  }
}
`

export default CustomersQuery
import React from 'react'
import { MacOrder } from '../../hooks/useMacOrders'
import moment from 'moment'
import './MacOrderRow.scss'
import iconFacebook from '../../assets/feather/facebook.svg'
import iconPhone from '../../assets/feather/phone.svg'
import iconShopify from '../../assets/feather/shopping-cart.svg'
import iconEmail from '../../assets/feather/mail.svg'

const NextActionMap = {
  follow_up: 'follow up',
  send_invoice: 'invoice',
  schedule_fulfillment: 'schedule',
  schedule_bake: 'schedule',
  bake: 'bake',
  pack: 'pack',
  fulfill: 'fulfill',
  none: 'none',
}
interface MacOrderRowProps extends MacOrder {
  onClick?: (order: MacOrder) => void
  bake?: boolean
}

const MacOrderRow: React.FC<MacOrderRowProps> = (props) => {
  const { onClick, bake, ...order } = props

  const urgency = React.useMemo(() => {
    if (order.nextAction === 'none') {
      return 'safe'
    }
    let milliseconds: number = moment(order.fulfillAt).diff(moment())

    const timeDay = 60 * 1000 * 60 * 24

    if (milliseconds >= timeDay * 7) {
      return 'green'
    }

    if (milliseconds > 0) {
      return 'amber'
    }

    if (milliseconds <= 0) {
      return 'urgent'
    }

    if (milliseconds <= timeDay) {
      return 'danger'
    }

    console.log('milliseconds', milliseconds, order.customerName)

    return 'test'
  }, [order])

  const fulfillDisplayTime = urgency === 'amber' ? moment(order.fulfillAt).format('ddd h:mma (D MMM)') : moment(order.fulfillAt).format('ddd D MMM')
  // const lineItems = React.useMemo(() => processLineItems(order), [order])

  return (
    <div key={order.uid} className={`MacOrderRow ${urgency}`} onClick={() => onClick && onClick(order)}>
      <span className={`Status ${urgency}`}>
        <span className={`tag ${order.nextAction}`}>
          {NextActionMap[order.nextAction]}
        </span>
        {order.customerType === 'delivery' ? <span>(Delivery)</span> : null}
      </span>
      <span className="CustomerName">{order.customerName}</span>
      <span className="Description">{order.orderDescription}</span>
      <span className="Source">{order.sources.map(source => {
        switch (source) {
          case 'facebook':
            return <img key={source} src={iconFacebook} width="16" alt="Facebook" />
          case 'phone':
            return <img key={source} src={iconPhone} width="16" alt="Phone" />
          case 'shopify':
            return <img key={source} src={iconShopify} width="16" alt="Shopify" />
          case 'email':
            return <img key={source} src={iconEmail} width="16" alt="Email" />
          default:
            return source
        }
      })}</span>
      <span className="PickUpDate">{fulfillDisplayTime}</span>
    </div>
  )
}

export default MacOrderRow
import React from 'react'
import { cloud } from '../state/config'
import moment from 'moment'
import objectHash from 'object-hash'
import * as uuid from 'uuid'

// • Lemon + White Chocolate, GF
// • Margarita, GF (no alcohol)
// • Crem Brûlée, GF
// • Jersey Caramel
// • Strawberry Oreo
// • VEGAN - Choc Fudge 

export const WEEKLY_FLAVOURS_COLLECTION = 'weekly_flavours'

export type WeeklyFlavour = {
  uid: string
  description: string
  title: string
  theme: string
  filterText: string
  startAt: string
  startAtMs: number
  endAt: string
  endAtMs: number
  createdAt: string
  createdAtMs: number
  updatedAt: string
  updatedAtMs: number
  hash: string
}

export const saveToCloud = (item: WeeklyFlavour) => {
  if (cloud.auth().currentUser) {
    item.uid = item.uid === '' ? uuid.v4().replace(/-/g, '') : item.uid
    const updated = hashWeeklyFlavour({
      ...item,
      createdAt: item.createdAt || moment().toISOString(true),
      createdAtMs: item.createdAtMs || moment().valueOf(),
      updatedAt: moment().toISOString(true),
      updatedAtMs: moment().valueOf(),
    })

    return cloud
      .firestore()
      .collection(WEEKLY_FLAVOURS_COLLECTION)
      .doc(item.uid)
      .set(updated, { merge: true })
  }

  throw new Error('User not logged in')
}

export const hashWeeklyFlavour = (order: WeeklyFlavour): WeeklyFlavour => {
  const { hash, ...macOrder } = order

  return {
    ...macOrder,
    hash: objectHash(macOrder)
  }
}

export const createBlankWeeklyFlavour = (): WeeklyFlavour => {
  return {
    uid: '',
    title: '',
    theme: '',
    description: '',
    startAt: moment().toISOString(true),
    startAtMs: moment().valueOf(),
    endAt: moment().add(5, 'days').toISOString(true),
    endAtMs: moment().add(5, 'days').valueOf(),
    createdAt: moment().toISOString(true),
    createdAtMs: moment().valueOf(),
    updatedAt: moment().toISOString(true),
    updatedAtMs: moment().valueOf(),
    filterText: '',
    hash: '',
  }
}

export const generateFilterText = (val: WeeklyFlavour) => {
  return [
    val.title,
    val.description,
    val.theme
  ].join(' ').toLowerCase()
}

const migrate = (val: WeeklyFlavour): WeeklyFlavour => {
  return val
}

const useWeeklyFlavours = (user?: firebase.User) => {
  const [items, setItems] = React.useState<WeeklyFlavour[]>()

  React.useEffect(() => {
    if (user) {
      return cloud
        .firestore()
        .collection(WEEKLY_FLAVOURS_COLLECTION)
        .onSnapshot((snap) => {
          const updates: WeeklyFlavour[] = []
          const deletions: string[] = []

          snap.docChanges().forEach(change => {
            deletions.push(change.doc.id)

            if (change.type !== 'removed') {
              deletions.push(change.doc.id)
              const data = change.doc.data() as WeeklyFlavour
              const migratedData = migrate(data)

              updates.push({
                ...migratedData,
                filterText: generateFilterText(data),
                uid: change.doc.id,
              })
            }
          })

          setItems(prev => {
            if (prev) {
              return [
                ...prev.filter(it => !deletions.includes(it.uid)),
                ...updates,
              ]
            }

            return updates
          })
        })
    }
  }, [user])

  return items
}

export default useWeeklyFlavours
function canonicalize(object: any): string {
  if (object === null || typeof object !== 'object' || object.toJSON != null) {
    return JSON.stringify(object);
  }
  if (Array.isArray(object) && object.length === 0) {
    return '[]';
  }
  if (Array.isArray(object) && object.length === 1) {
    return '[' + canonicalize(object[0]) + ']';
  }
  if (Array.isArray(object)) {
    return '[' + object.reduce((t, cv, ci) => {
      t = (ci === 1 ? canonicalize(t) : t);
      return t + ',' + canonicalize(cv);
    }) + ']';
  }
  const keys = Object.keys(object);
  if (keys.length === 0) {
    return '{}';
  }
  if (keys.length === 1) {
    return '{' + canonicalize(keys[0]) + ':' + canonicalize(object[keys[0]]) + '}';
  }
  return '{' + keys.sort().reduce((t, cv, ci) => {
    t = (ci === 1 ? canonicalize(t) + ':' + canonicalize(object[t]) : t);
    return t + ',' + canonicalize(cv) + ':' + canonicalize(object[cv]);
  }) + '}';
}
export default canonicalize
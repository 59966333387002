import React from 'react'
import { cloud } from '../state/config'
import { useGenericFirestoreCollectionHook } from './useGenericFirestore'

export type Address = {
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  postalCode: string
}

export type CustomerShippingDetails = {
  name: string | null
  phone: string | null
  instructions: string | null
  addressLine1: string
  addressLine2: string
  city: string
  state: string
  postalCode: string
}

export type Customer = {
  uid: string
  name: string
  address: Address | null
  firstName: string | null
  lastName: string | null
  email: string | null
  mobile: string | null
  phone: string | null
  shippingDetails: CustomerShippingDetails | null
}

const ref = cloud.firestore().collection('/customers')

const useCustomers = (user?: firebase.User) => {
  const customers = useGenericFirestoreCollectionHook<Customer>(user, ref)

  return customers
}

export default useCustomers
const InvoicesQuery = `
  query($id: ID!, $page: Int!, $pageSize: Int!) {
    business (id: $id) {
      invoices (page: $page, pageSize: $pageSize, sort: INVOICE_DATE_DESC) {
        pageInfo {
          currentPage
          totalPages
        }
        edges {
          node {
            id
            invoiceNumber
            invoiceDate
            dueDate
            memo
            total {
              raw
            }
            amountDue {
              raw
            }
            amountPaid {
              raw
            }
            customer {
              name
              email
            }
            items	{
              unitPrice
              quantity
              description
              product {
                name
              }
            }
          }
        }
      }
    }
  }
`

export default InvoicesQuery
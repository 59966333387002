import React from 'react'
import { RootContext } from '../../state/RootContext'
import useMacOrders, { MacOrder, processLineItems, processLineItemsVerbose } from '../../hooks/useMacOrders'
import moment from 'moment'
import * as uuid from 'uuid'
import './BakeSchedule.scss'
import MacOrderRow from '../Orders/MacOrderRow'
import lodash from 'lodash'
import FlavourRow from './FlavourRow'

const filterNotFinished = (a: MacOrder) => {
  return a.nextAction !== 'none'
}

const filterByThisWeek = (a: MacOrder) => {
  const firstDayOfWeek = moment().startOf('week')
  const lastDayOfWeek = moment().endOf('week').endOf('day').add(1, 'day')
  return moment(a.fulfillAt).isBetween(firstDayOfWeek, lastDayOfWeek)
}

const sortByDateAsc = (a: MacOrder, b: MacOrder) => {
  if (moment(a.fulfillAt).diff(moment(b.fulfillAt)) === 0) {
    return a.customerName.localeCompare(b.customerName)
  }

  return moment(a.fulfillAt).diff(moment(b.fulfillAt))
}

type ParsedBake = {
  id: string
  orders: MacOrder[]
  ordersUids: string[]
  count: number
  countExtra: number
  title: string
}

const normalize = (str: string) => str.replace(/\s/gi, '').toLowerCase()

const BakeSchedule = () => {
  const { state } = React.useContext(RootContext)
  const { user } = state
  const ordersRaw = useMacOrders(user)
  const [sort, setSort] = React.useState<'count' | 'title'>('title')
  const activeOrders = React.useMemo(() => ordersRaw && ordersRaw.filter(filterNotFinished), [ordersRaw])
  const thisWeekOnly = React.useMemo(() => activeOrders && activeOrders.filter(filterByThisWeek), [activeOrders])
  const orders = React.useMemo(() => thisWeekOnly && thisWeekOnly.sort(sortByDateAsc), [thisWeekOnly])
  const lineItems = React.useMemo(() => {
    if (orders) {
      const items = orders.reduce((prev, next) => {
        return [
          ...prev,
          ...processLineItems(next),
        ]
      }, [] as string[])

      const parsed = items
        .map(it => {
          const matchedOrders = orders.filter(order => processLineItems(order).map(normalize).includes(normalize(it)))
          return {
            id: uuid.v4(),
            orders: matchedOrders,
            ordersUids: matchedOrders.map(order => order.uid),
            count: parseInt(it),
            countExtra: parseInt(it) + 2,
            title: it.split(' ').slice(1).join(' ').trim() // Removes number (20x) component
          }
        })

      const parsedCombined: ParsedBake[] = []

      parsed.forEach(p => {
        const existing = parsedCombined.find(it => normalize(it.title) === normalize(p.title))
        if (existing) {
          existing.count += p.count
          existing.countExtra += p.countExtra
          existing.orders = lodash.uniqBy([...existing.orders, ...p.orders], 'uid')
          existing.ordersUids = lodash.uniq([...existing.ordersUids, ...p.ordersUids])
        } else {
          parsedCombined.push(p)
        }
      })

      return parsedCombined.sort((a, b) => {
        if (sort === 'count') {
          return b.count - a.count
        }
        return a.title.localeCompare(b.title)
      })
    }

    return []
  }, [orders, sort])

  const missingOrders = React.useMemo(() => {
    if (ordersRaw) {
      return ordersRaw
        .filter(it => !lineItems.find(item => item.ordersUids.includes(it.uid)))
        .filter(filterByThisWeek)
        .sort(sortByDateAsc)
        .filter(it => it.nextAction !== 'none')
    }

    return undefined
  }, [ordersRaw, lineItems])

  const totals = React.useMemo(() => {
    return lineItems.reduce((prev, next) => {
      const { count, countExtra, batches, batchesExtra } = calculate(next)

      return {
        totalCount: prev.totalCount + count,
        totalCountExtra: prev.totalCountExtra + countExtra,
        totalBatches: prev.totalBatches + batches,
        totalBatchesExtra: prev.totalBatchesExtra + batchesExtra,
      }
    }, {
      totalCount: 0,
      totalCountExtra: 0,
      totalBatches: 0,
      totalBatchesExtra: 0,
    })
  }, [lineItems])

  const inStockOrders = React.useMemo(() => lineItems.filter(it => it.title.includes('(in-stock)')), [lineItems])
  const customOrders = React.useMemo(() => lineItems.filter(it => it.title.includes('(custom)')), [lineItems])
  const otherOrders = React.useMemo(() => lineItems.filter(it => !it.title.includes('(in-stock)') && !it.title.includes('(custom)')), [lineItems])

  const groupedByFlavours = React.useMemo(() => {
    if (orders) {
      const grouped = lodash.groupBy(orders
        .map(processLineItemsVerbose)
        .reduce((prev, next) => [...prev, ...next], []), 'flavour')

      return Object.values(grouped)
    }
    return []
  }, [orders])

  return (
    <div className="BakeSchedule">
      <h1>Bake Schedule</h1>

      <p>These are actual order numbers</p>
      <div className="GroupedContainer">
        {groupedByFlavours.map(group => (
          <FlavourRow key={group[0].flavour} group={group} styles={['classic', 'signature']} />
        ))}

      </div>
      <div className="GroupedContainer">
        {groupedByFlavours.map(group => (
          <FlavourRow key={group[0].flavour} group={group} styles={['custom']} />
        ))}
      </div>

      <p>Below are calculated</p>

      <div className="Row SortButtons">
        <button className={`button ${sort === 'count' ? 'active' : ''}`} onClick={() => setSort('count')}>Sort by Count</button>
        <button className={`button ${sort === 'title' ? 'active' : ''}`} onClick={() => setSort('title')}>Sort by Name</button>
      </div>

      {missingOrders && missingOrders.length > 0 && (
        <div className="Missing">
          <h3>Missing</h3>
          <div className="Row">
            {missingOrders.map((it, index) => <MacOrderRow key={index} bake {...it} />)}
          </div>
        </div>
      )}

      <div className="BakeRow">
        <div className="Row HeaderRow">
          <span className="ColumnMacarons">Units</span>
          <span className="ColumnBatches">Batch</span>
          <span className="ColumnMacaronsExtra">Units+</span>
          <span className="ColumnBatchesExtra">Batch+</span>
          <span className="ColumnTitle">Name</span>
          <span className="ColumnExtras">Extras</span>
          <span className="ColumnTotalOrders">Total Orders</span>
        </div>
        <div className="Row Totals">
          <span className="ColumnMacarons">{totals.totalCount}</span>
          <span className="ColumnBatches">{totals.totalBatches}</span>
          <span className="ColumnMacaronsExtra">{totals.totalCountExtra}</span>
          <span className="ColumnBatchesExtra">{totals.totalBatchesExtra}</span>
          <span className="ColumnTitle"></span>
          <span className="ColumnExtras"></span>
          <span className="ColumnTotalOrders"></span>
        </div>
      </div>

      <h4>Others</h4>
      {otherOrders.map(it => <BakeRow key={it.id} {...it} />)}

      <h4>Custom</h4>
      {customOrders.map(it => <BakeRow key={it.id} {...it} />)}

      <h4>In Stock</h4>
      {inStockOrders.map(it => <BakeRow key={it.id} {...it} />)}
    </div>
  )
}

const calculate = (bake: ParsedBake) => {
  const { title, count, countExtra, orders } = bake
  const countPerBatch = normalize(title).includes('vegan') ? 30 : 60
  const batches = Math.ceil(count / countPerBatch)
  const batchesExtra = Math.ceil(countExtra / countPerBatch)

  return {
    title,
    count,
    countExtra,
    countPerBatch,
    batches,
    batchesExtra,
    totalOrders: orders.length
  }
}

const BakeRow: React.FC<ParsedBake> = (props) => {
  const { orders } = props
  const { title, count, countExtra, batches, batchesExtra, totalOrders } = React.useMemo(() => calculate(props), [props])
  const [expand, setExpand] = React.useState(false)

  return (
    <div className="BakeRow">
      <div className="Row">
        <span className="Count ColumnMacarons">{count}</span>
        <span className="Count ColumnBatches">{batches}</span>
        <span className="Count ColumnMacaronsExtra">{countExtra}</span>
        <span className="Count ColumnBatchesExtra">{batchesExtra} batches</span>
        <span className="Title ColumnTitle">{title}</span>
        <button className="button ColumnExtras" onClick={() => setExpand(prev => !prev)}>More</button>
        <span className="Count ColumnTotalOrders">{totalOrders}</span>
      </div>
      {expand && (
        <div className="Row Customers">
          {orders.sort((a, b) => a.customerName.localeCompare(b.customerName)).map((it, index) => <MacOrderRow key={index} bake {...it} />)}
        </div>
      )}
    </div>
  )
}

export default BakeSchedule
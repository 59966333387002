import React from 'react'
import classNames from 'classnames'

import './TextInput.scss'

export type TextInputOrientation = 'vertical' | 'horizontal'

export interface TextInputProps {
  label?: string

  onChange: (val: string) => void
  onBlur?: () => void
  onFocus?: () => void
  onClick?: () => void
  onKeyUp?: (e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => void

  value: string

  enabled?: boolean
  type?: 'password' | 'text' | 'email'
  textarea?: boolean
  placeholder?: string

  className?: string
  error?: boolean
  inputStyle?: 'rounded'
  orientation?: TextInputOrientation
}

const TextInput: React.FC<TextInputProps> = ({
  className, textarea, label, value, enabled = true, onChange, orientation, type, placeholder, inputStyle, error, onBlur, onFocus, onKeyUp,
}) => {
  return (
    <label className={classNames('TextInput', orientation ?? 'vertical', inputStyle, className, { error })}>
      {label && <span className="label">{label}</span>}
      {textarea && (
        <textarea
          disabled={!enabled}
          value={value ?? ''}
          placeholder={placeholder ?? label}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
        />
      )}

      {!textarea && (
        <input
          type={type ?? 'text'}
          disabled={!enabled}
          value={value ?? ''}
          placeholder={placeholder ?? label}
          onChange={e => onChange(e.target.value)}
          onBlur={onBlur}
          onFocus={onFocus}
          onKeyUp={onKeyUp}
        />
      )}
    </label>
  )
}

export default TextInput
import React from 'react'
import TextInput from '../TextInput'
import { cloud } from '../../state/config'
import ErrorBox from '../ErrorBox'

const SignUp = () => {
  const [email, setEmail] = React.useState('')
  const [password, setPassword] = React.useState('')
  const [error, setError] = React.useState<Error>()
  const [user, setUser] = React.useState<firebase.auth.UserCredential>()

  const onSubmit = React.useCallback(() => {
    setError(undefined)

    cloud
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(setUser)
      .catch(setError)
  }, [email, password])

  return (
    <div className="SignUp">
      {error && <ErrorBox error={error} />}
      {user && <p>User created successfully.</p>}

      {!user && (
        <form onSubmit={onSubmit}>
          <h1>Register</h1>
          <TextInput label="email" value={email} onChange={setEmail} />
          <TextInput label="password" type="password" value={password} onChange={setPassword} />
          <button className="button secondary">Register</button>
        </form>
      )}
    </div>
  )
}

export default SignUp
import React from 'react'
import { WaveCustomer } from '../../hooks/useWaveInvoices'
import './MiniCustomer.scss'

interface MiniCustomerProps extends WaveCustomer {
  synced: boolean
}

const MiniCustomer: React.FC<MiniCustomerProps> = (props) => {
  const { id, name, firstName, lastName, email, shippingDetails, synced } = props

  return (
    <div className={`MiniCustomer ${synced ? 'Synced' : 'NotSynced'}`}>
      <div className="Row">
        <div className="Customer">
          <span className="Name">{name}</span>
          {name.toLowerCase() !== `${firstName} ${lastName}`.toLowerCase() && (
            <span className="FullName">{firstName} {lastName}</span>
          )}
          <span className="Email">{email}</span>

          {shippingDetails && (
            <span className="ShippingDetails">
              <span>{shippingDetails.name}</span>
              <span>{shippingDetails.phone}</span>
              <span>{shippingDetails.address?.addressLine1}</span>
              <span>{shippingDetails.address?.addressLine2}</span>
              <span>{shippingDetails.address?.city}</span>
              <span>{shippingDetails.address?.postalCode}</span>
            </span>
          )}
        </div>
      </div>
    </div>
  )
}

export default MiniCustomer
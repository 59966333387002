import React from 'react'
import './Inventory.scss'
import { useGenericFirestoreCollectionHook } from '../../hooks/useGenericFirestore'
import { cloud } from '../../state/config'
import { RootContext } from '../../state/RootContext'
import Modal from '../../components/Modal'
import TextInput from '../../components/TextInput'

type InventoryItem = {
  uid: string
  name: string
  qty: number
}

const Inventory = () => {
  const { state } = React.useContext(RootContext)
  const { user } = state
  const ref = React.useMemo(() => user && cloud.firestore().collection('inventory'), [user])
  const inventory = useGenericFirestoreCollectionHook<InventoryItem>(user, ref)
  const onUpdate = React.useCallback((uid: string, qty: number) => ref?.doc(uid).set({ qty }, { merge: true }), [ref])
  const [editItem, setEditItem] = React.useState<InventoryItem>()
  const onAdd = React.useCallback(() => setEditItem({ uid: '', name: '', qty: 0 }), [])
  const onSave = React.useCallback(() => {
    if (editItem && ref) {
      if (editItem.uid === '') {
        ref.add(editItem)
      } else {
        ref.doc(editItem.uid).set(editItem, { merge: true })
      }

      setEditItem(undefined)
    }
  }, [editItem, ref])

  const warningLevel = React.useCallback((qty: number) => {
    if (qty <= 10) {
      return 'red'
    }
    if (qty <= 20) {
      return 'orange'
    }
    return 'green'
  }, [])

  return (
    <div className="Inventory">
      <button className="button" onClick={onAdd}>Add</button>

      {inventory && inventory.sort((a, b) => a.name.localeCompare(b.name)).map(it => (
        <div className="InventoryRow" key={it.uid}>
          <span className="Name">{it.name}</span>
          <div className="Actions">
            <button type="button" className="button" onClick={() => onUpdate(it.uid, it.qty - 1)}>-</button>
            <span className={`Qty ${warningLevel(it.qty)}`}>{it.qty}</span>
            <button type="button" className="button" onClick={() => onUpdate(it.uid, it.qty + 1)}>+</button>
          </div>
          <button type="button" className="Edit button" onClick={() => setEditItem(it)}>Edit</button>
        </div>
      ))}

      {editItem && (
        <Modal className="EditInventory" onClose={() => setEditItem(undefined)}>
          <form onSubmit={onSave}>
            <TextInput label="Name" value={editItem.name} onChange={name => setEditItem(prev => prev ? ({ ...prev, name }) : undefined)} />
            <TextInput label="Qty" value={`${editItem.qty}`} onChange={qty => setEditItem(prev => prev ? ({ ...prev, qty: parseInt(qty) }) : undefined)} />
            <button type="submit" className="SaveButton button" onClick={onSave}>Save</button>
          </form>
        </Modal>
      )}

    </div>
  )
}

export default Inventory
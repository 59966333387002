import React from 'react'
import firebase from 'firebase/app'
import 'firebase/auth'

type useFirebaseAuthType = (app: firebase.app.App, waitTimeout: number) => {
  user?: firebase.User
  firebaseToken?: string
  signIn: (email: string, password: string) => void
  signOut: () => Promise<void>
  fetching: boolean
  error?: Error
}

const useFirebaseAuth: useFirebaseAuthType = (app: firebase.app.App) => {
  const [user, setUser] = React.useState<firebase.User>() // app.auth().currentUser
  const [firebaseToken, setFirebaseToken] = React.useState<string>()
  const [error, setError] = React.useState<Error>()
  const [fetching, setFetching] = React.useState(true)

  React.useLayoutEffect(() => {
    setFetching(true)

    app.auth()
      .onAuthStateChanged(async (authUser) => {
        setError(undefined)
        setUser(authUser ?? undefined)
        setFetching(false)

        if (authUser) {
          setFirebaseToken(await authUser?.getIdToken())
        } else {
          setFirebaseToken(undefined)
        }
      })
  }, [app])

  const signIn = (email: string, password: string) => {
    setError(undefined)
    setFetching(true)

    app.auth()
      .signInWithEmailAndPassword(email, password)
      .catch(setError)
      .finally(() => setFetching(false))
  }

  const signOut = () => {
    setError(undefined)
    setFetching(true)

    return app.auth()
      .signOut()
      .then(() => setUser(undefined))
      .catch(setError)
      .finally(() => setFetching(false))
  }

  return {
    user: fetching ? undefined : user,
    firebaseToken,
    signIn,
    signOut,
    fetching,
    error,
  }
}

export default useFirebaseAuth
import React from 'react'
import ReactDatePicker, { ReactDatePickerProps } from 'react-datepicker'

import 'react-datepicker/dist/react-datepicker.css'
import './CalendarDatePicker.scss'

const CalendarDatePicker: React.FC<
  ReactDatePickerProps & { reactDatePickerRef?: React.Ref<ReactDatePicker> }
> = (props) => {
  const { reactDatePickerRef, ...reactDatePickerProps } = props

  return (
    <ReactDatePicker
      ref={reactDatePickerRef}
      {...reactDatePickerProps}
      calendarClassName="CalendarDatePicker"
    />
  )
}

export default CalendarDatePicker

import React from 'react'
import {
  Route,
  Redirect,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom'
import { cloud } from '../state/config'
import { RootStorageKeys } from '../state/RootContext'

const PrivateRoute: React.FC<RouteProps> = ({ children, path, ...props }) => {
  const render = React.useCallback(({ location }: RouteComponentProps<any>) => {
    if (cloud.auth().currentUser) {
      if (typeof path === 'string') {
        localStorage.setItem(RootStorageKeys.lastPage, path)
      }
      return children
    }

    return <Redirect to={{ pathname: '/login', state: { from: location } }} />
  }, [children, path])

  return (
    <Route {...props} render={render} />
  )
}

export default PrivateRoute
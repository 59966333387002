import React from 'react'

const NEW_SESSION = 'NEW_SESSION'
export const SIGN_IN = 'SIGN_IN'
export const SIGN_OUT = 'SIGN_OUT'
export const USER_DETAILS_FETCHED = 'USER_DETAILS_FETCHED'

export enum RootStorageKeys {
  lastPage = 'macaron-bar.root.lastPage',
}

export type RootState = {
  user?: firebase.User
  firebaseToken?: string
  fetching: { [key: string]: boolean }
}

export const initialRootState: RootState = {
  fetching: {},
}

export type RootActions = { type: typeof NEW_SESSION }
  | { type: typeof SIGN_IN, user: firebase.User, firebaseToken: string }
  | { type: typeof SIGN_OUT }

export const RootContext = React.createContext<{ state: RootState, dispatch: (action: RootActions) => void }>({
  state: initialRootState,
  dispatch: () => { return },
})

export const rootReducer = (state: RootState, action: RootActions): RootState => {
  if (process.env.NODE_ENV === 'development') {
    console.log(action)
  }

  switch (action.type) {
    case SIGN_IN:
      return {
        ...state,
        user: action.user,
        firebaseToken: action.firebaseToken,
      }

    case SIGN_OUT:
      return { ...initialRootState }
  }

  return state
}
const TestQuery = `
query {
  shop {
    id
    name
    email
  }
}
`

export default TestQuery
import React from 'react'

const onSnapshot = <T>(snap: firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>) => {
  const updates: T[] = []
  const deletions: string[] = []

  snap.docChanges().forEach(change => {
    deletions.push(change.doc.id)

    if (change.type !== 'removed') {
      deletions.push(change.doc.id)
      const data = change.doc.data() as T

      updates.push({
        ...data,
        uid: change.doc.id,
      })
    }
  })

  return {
    updates,
    deletions,
  }
}

export const useGenericFirestoreCollectionHook = <T>(user: undefined|firebase.User, ref?: firebase.firestore.CollectionReference<firebase.firestore.DocumentData>): T[] | undefined => {
  const [items, setItems] = React.useState<T[]>()

  React.useEffect(() => {
    if (user && ref) {
      return ref
        .onSnapshot(snap => {
          const { updates, deletions } = onSnapshot<T>(snap)
          setItems(prev => ([
            ...(prev || []).filter(it => !deletions.includes((it as any).uid)),
            ...updates,
          ]))
        })
    }
  }, [user, ref])

  return items
}

export const useGenericFirestoreDocumentHook = <T>(user: undefined | firebase.User, ref: undefined | firebase.firestore.DocumentReference<firebase.firestore.DocumentData>): { data: T | undefined, onSave: (change: any) => Promise<void> } => {
  const [item, setItem] = React.useState<T>()

  const onSave = React.useCallback(async (val: any) => {
    if (user && ref) {
      return await ref.set(JSON.parse(JSON.stringify(val)), { merge: true })
    }
  }, [user, ref])

  React.useEffect(() => {
    setItem(undefined)

    if (user && ref) {
      return ref
        .onSnapshot(snap => {
          const it = snap.data() as any

          if (it) {
            setItem({ uid: snap.id, ...it })
          }
        })
    }
  }, [user, ref])

  return {
    data: item,
    onSave,
  }
}
import React from 'react'
import { useWaveGraphQLClient, InvoicesQueryResponse, CustomersQueryResponse } from '../../hooks/useWaveInvoices'
import { useQuery, Provider } from 'urql'
import CustomersQuery from '../../queries/wave/CustomersQuery'
import MiniCustomer from './MiniCustomer'
import './WaveCustomers.scss'
import { RootContext } from '../../state/RootContext'
import useCustomers, { Customer } from '../../hooks/useCustomers'
import { cloud } from '../../state/config'

type WaveCustomersProps = {
  businessId?: string
}

const WaveCustomers: React.FC<WaveCustomersProps> = (props) => {
  const { state } = React.useContext(RootContext)
  const { user } = state
  const { businessId } = props
  const customers = useCustomers(user)
  const [page, setPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(25)
  const [result, reexecuteQuery] = useQuery({
    query: CustomersQuery,
    variables: { 
      id: businessId,
      page, 
      pageSize
    },
  })
  const { data: rawData, fetching, error } = result
  const data: undefined | CustomersQueryResponse = rawData
  const [syncing, setSyncing] = React.useState(false)

  const performSync = React.useCallback(() => {
    if (customers && data?.business.customers) {
      setSyncing(true)
      const batch = cloud.firestore().batch()

      data.business.customers.edges.forEach(it => {
        const waveCustomer = it.node
        const customer: Customer = {
          uid: waveCustomer.id,
          name: waveCustomer.name,
          address: {
            addressLine1: waveCustomer.address?.addressLine1 || '',
            addressLine2: waveCustomer.address?.addressLine2 || '',
            city: waveCustomer.address?.city || '',
            state: waveCustomer.address?.province?.name || '',
            postalCode: waveCustomer.address?.postalCode || '',
          },
          firstName: waveCustomer.firstName,
          lastName: waveCustomer.lastName,
          email: waveCustomer.email,
          mobile: waveCustomer.mobile,
          phone: waveCustomer.phone,
          shippingDetails: {
            name: waveCustomer.shippingDetails?.name || '',
            phone: waveCustomer.shippingDetails?.phone || '',
            instructions: waveCustomer.shippingDetails?.instructions || '',
            addressLine1: waveCustomer.shippingDetails?.address?.addressLine1 || '',
            addressLine2: waveCustomer.shippingDetails?.address?.addressLine2 || '',
            city: waveCustomer.shippingDetails?.address?.city || '',
            state: waveCustomer.shippingDetails?.address?.province?.name || '',
            postalCode: waveCustomer.shippingDetails?.address?.postalCode || '',
          }
        }

        const ref = cloud.firestore().collection('customers').doc(customer.uid)
        batch.set(ref, JSON.parse(JSON.stringify(customer)), { merge: true })
      })

      batch.commit().finally(() => setSyncing(false))
    }
  }, [customers, data])

  const isSynced = React.useCallback((uid: string) => {
    if (customers) {
      return customers.find(it => it.uid === uid) !== undefined
    }
    return false
  }, [customers])

  if (fetching) return <p>Loading...</p>
  if (error) return <p>Oh no... {error.message}</p>

  const totalPages = data?.business.customers.pageInfo.totalPages
  const currentPage = data?.business.customers.pageInfo.currentPage

  return (
    <div className="WaveCustomers">
    {syncing && <h1>Syncing....</h1>}
    <button className="button" type="button" onClick={performSync}>Sync Customers</button>

    {totalPages !== undefined && currentPage !== undefined && (
      <div className="Paginator">
        <button className="button" type="button" onClick={() => setPage(prev => Math.max(1, prev - 1))}>Back</button>
        <span>{currentPage} of {totalPages}</span>
        <button className="button" type="button" onClick={() => setPage(prev => Math.min(totalPages, prev + 1))}>Next</button>
      </div>
    )}

    <div className="CustomersContainer">
        {data?.business.customers.edges.map(it => <MiniCustomer key={it.node.id} {...it.node} synced={isSynced(it.node.id)}/>)}
    </div>
    </div>
  )
}


export default WaveCustomers
import React from 'react'
import useWeeklyFlavours, { createBlankWeeklyFlavour, WeeklyFlavour } from '../../hooks/useWeeklyFlavours'
import { RootContext } from '../../state/RootContext'
import Modal from '../../components/Modal'
import WeekyFlavourForm from './WeekyFlavourForm'
import './WeeklyFlavours.scss'

const WeeklyFlavours = () => {
  const { state } = React.useContext(RootContext)
  const { user } = state
  const flavours = useWeeklyFlavours(user)
  const [flavour, setFlavour] = React.useState<WeeklyFlavour>()

  const onNew = React.useCallback(() => setFlavour(createBlankWeeklyFlavour()), [])
  const onClose = React.useCallback(() => setFlavour(undefined), [])

  return (
    <div className="WeeklyFlavours">
      <div className="ActionContainer">
        <button className="button" type="button" disabled={flavour !== undefined} onClick={onNew}>New</button>
      </div>

      {flavours && flavours.map(it => (
        <div className="FlavourRow" key={it.uid}>
          <div>
            <label>{it.title}</label>
            <pre>{it.description}</pre>
          </div>
          <div>
            <button className="button" onClick={() => setFlavour(it)}>Edit</button>
          </div>
        </div>
      ))}

      {flavour && (
        <Modal>
          <WeekyFlavourForm {...flavour} onClose={onClose} />
        </Modal>
      )}
    </div>
  )
}

export default WeeklyFlavours
import React from 'react'
import { ShopifyOrder } from '../../hooks/useFirestoreShopifyOrders'
import { MacOrder, createBlankOrder, generateFilterText, hashOrder, saveToCloud } from '../../hooks/useMacOrders'
import MacOrderRow from '../Orders/MacOrderRow'
import moment from 'moment'
import lodash from 'lodash'
import './ShopifyOrderRow.scss'

const lineItemsToText = (order: ShopifyOrder): string => {
  return order
    .line_items
    .map(it => `${it.title} ${it.price}`)
    .join('\n')
}

interface ShopifyOrderRowProps extends ShopifyOrder {
}

const ShopifyOrderRow: React.FC<ShopifyOrderRowProps> = (props) => {
  const { ...order } = props
  const { created_at, order_number, customer, email, line_items, shipping_lines, total_price, shipping_address, billing_address } = order
  const { first_name, last_name } = customer
  const { phone: billingPhone } = billing_address
  const { phone: shippingPhone } = shipping_address
  const [saving, setSaving] = React.useState(false)

  const isPickup = React.useMemo(() => {
    if (shipping_lines.length > 0) {
      return shipping_lines[0].title.includes('Arrange another day')
    }
  }, [shipping_lines])

  const shippingText = React.useMemo(() => {
    if (isPickup) {
      return 'Arrange pick-up'
    }

    return `Unknown (${shipping_lines[0]?.title})`
  }, [isPickup, shipping_lines])

  const createNewMacOrder = React.useCallback(() => {
    setSaving(true)
    const blank = createBlankOrder()
    blank.uid = ''
    blank.customerName = `${order.customer.first_name} ${order.customer.last_name}`
    blank.customerType = isPickup ? 'pickup' : 'delivery'
    blank.customerEmail = order.email
    blank.customerPhone = lodash.uniq([
      (order.shipping_address.phone || '').replace(/\D/g,''),
      (order.billing_address.phone || '').replace(/\D/g,''),
      (order.customer.phone || '').replace(/\D/g,''),
    ]).filter(it => it !== '').join(', ');
    blank.customerAddress = 'TODO'
    blank.orderDescription = lineItemsToText(order)
    blank.paymentStatus = 'paid'
    blank.invoiceNumber = `Shopify ${order.order_number}`
    blank.fulfillAt = moment().add(1, 'day').toISOString(true)
    blank.sources = ['shopify']
    blank.filterText = generateFilterText(blank)
    blank.hash = hashOrder(blank).hash

    saveToCloud(blank)
      .finally(() => setSaving(false))
  }, [isPickup, order])
  
  return (
    <div className="ShopifyOrderRow">
      <div className="OrderNumberCustomer">
        <span className="OrderDate">{moment(created_at).format('LLLL')}</span>
        <span className="OrderNumber">
          <span>{order_number}</span>
          <span>${total_price}</span>
        </span>
        <span className="Name">{first_name} {last_name}</span>
        <span className="Email">{email}</span>
        {(billingPhone === shippingPhone) && <span className="Phone">Phone: {shippingPhone}</span>}
        {billingPhone !== shippingPhone && (
          <>
            {billingPhone !== '' && <span className="Phone">Billing: {billingPhone}</span>}
            {shippingPhone !== '' && <span className="Phone">Shipping: {shippingPhone}</span>}
          </>
        )}
        {!shippingText.includes('undefined') && <span className="Shipping">{shippingText}</span>}
      </div>
      <div className="LineItems">
        {line_items
          .sort((a, b) => a.name.localeCompare(b.name))
          .map(line => (
            <p key={line.id}>
              <strong>{line.quantity}</strong>
              <span>{line.name}</span>
            </p>
          ))}
      </div>
    </div>
  )
}

export default ShopifyOrderRow
import React from 'react'
import firebase from 'firebase'
import { cloud } from '../state/config'
import objectHash from 'object-hash'
import moment from 'moment'
import * as uuid from 'uuid'
import lodash from 'lodash'

export const processLineItems = (order: MacOrder): string[] => {
  return lodash.uniq(
    order.orderDescription
      .split('\n')
      .map(it => {
        const matches = it.match(/^\d{1,3}\d?x\s.*/gi)
        return matches?.pop()
      })
      .filter(it => it) as string[]
  )
}

export type ProcessLineItem = {
  qty: number
  style: string
  flavour: string
  flavourNormalized: string
  raw: string
}

const normalize = (str: string) => str.replace(/\s/gi, '').toLowerCase()

export const processLineItemsVerbose = (order: MacOrder): ProcessLineItem[] => {
  const lineItemsText = processLineItems(order)
  return lineItemsText.map(raw => {
    let style = ''

    if (raw.toLowerCase().includes('classic')) {
      style = 'classic'
    }

    if (raw.toLowerCase().includes('signature')) {
      style = 'signature'
    }

    if (raw.toLowerCase().includes('(in-stock)')) {
      style = '(in-stock)'
    }

    if (style === '') {
      style = 'custom'
    }

    const index = raw.toLowerCase().indexOf(style)
    const flavour = style === 'custom' ? raw.trim() : raw.slice(index + style.length).trim()

    return {
      qty: parseInt(raw),
      style,
      flavour,
      flavourNormalized: normalize(flavour),
      raw,
    }
  })
}

export const saveToCloud = (order: MacOrder) => {
  if (cloud.auth().currentUser) {
    order.uid = order.uid === '' ? uuid.v4().replace(/-/g, '') : order.uid
    const updated = hashOrder({
      ...order,
      createdAt: order.createdAt || moment().toISOString(true),
      createdAtMs: order.createdAtMs || moment().valueOf(),
      updatedAt: moment().toISOString(true),
      updatedAtMs: moment().valueOf(),
    })

    return cloud
      .firestore()
      .collection('mac_orders')
      .doc(order.uid)
      .set(updated, { merge: true })
  }

  throw new Error('User not logged in')
}

export const createBlankOrder = (): MacOrder => {
  return {
    uid: '',
    customerName: '',
    customerType: 'pickup',
    customerEmail: '',
    customerPhone: '',
    customerAddress: '',
    orderDescription: '',
    paymentStatus: 'draft',
    nextAction: 'follow_up',
    invoiceNumber: '',
    fulfillAt: moment().endOf('week').subtract(1, 'day').hours(17).minutes(0).toISOString(true),
    createdAt: moment().toISOString(true),
    createdAtMs: moment().valueOf(),
    updatedAt: moment().toISOString(true),
    updatedAtMs: moment().valueOf(),
    sources: [],
    filterText: '',
    hash: '',
  }
}

export const hashOrder = (order: MacOrder): MacOrder => {
  const { hash, ...macOrder } = order

  return {
    ...macOrder,
    hash: objectHash(macOrder)
  }
}

export type MacOrderSource = 'facebook' | 'shopify' | 'phone' | 'email'

export type MacOrder = {
  uid: string
  sources: MacOrderSource[] // Deprecated
  customerName: string
  customerType: 'pickup' | 'delivery' | 'stockist'
  customerPhone: string
  customerEmail: string
  customerAddress: string
  orderDescription: string
  paymentStatus: 'donation' | 'draft' | 'invoiced' | 'invoiced_cash' | 'paid' | 'paid_cash'
  nextAction: 'follow_up' | 'send_invoice' | 'schedule_fulfillment' | 'schedule_bake' | 'bake' | 'pack' | 'fulfill' | 'none'
  invoiceNumber: string
  fulfillAt: string
  createdAt: string
  createdAtMs: number
  updatedAt: string
  updatedAtMs: number
  filterText: string
  hash: string
}

export const generateFilterText = (order: MacOrder) => {
  return [
    order.customerType,
    order.customerPhone,
    order.customerAddress,
    order.customerEmail,
    order.customerName,
    order.orderDescription,
    order.nextAction,
    order.invoiceNumber,
    ...(Array.isArray(order.sources) ? order.sources : [`${order.sources}`]),
    moment(order.fulfillAt).format('MMMM'),
    moment(order.fulfillAt).format('LL')
  ]
    .filter(it => it !== undefined && it !== 'undefined')
    .join(' ')
    .toLowerCase()
}

const migrate = (data: MacOrder) => {
  const {
    followUpAt, // Deprecated
    followUpDescription, // Deprecated
    pickUpAt, // Deprecated
    deliverAt, // Deprecated
    nextAction,
    sources,
    orderStatus, // Deprecated
    ...order
  } = data as any

  const fulfillAt = [
    order.fulfillAt,
    data.customerType === 'pickup' ? pickUpAt : undefined, // Deprecated
    data.customerType === 'delivery' ? deliverAt : undefined, // Deprecated
    moment().toISOString(true),
  ].find(it => typeof it === 'string')

  return {
    ...order,
    sources: Array.isArray(sources) ? sources : [],
    nextAction: nextAction ? nextAction : 'follow_up',
    fulfillAt,
  }
}

const useMacOrders = (user?: firebase.User): MacOrder[] | undefined => {
  const [items, setItems] = React.useState<MacOrder[]>()

  React.useEffect(() => {
    if (user) {
      return cloud
        .firestore()
        .collection('mac_orders')
        .onSnapshot((snap) => {
          const updates: MacOrder[] = []
          const deletions: string[] = []

          snap.docChanges().forEach(change => {
            deletions.push(change.doc.id)

            if (change.type !== 'removed') {
              deletions.push(change.doc.id)
              const data = change.doc.data() as MacOrder
              const migratedData = migrate(data)

              updates.push({
                ...migratedData,
                filterText: generateFilterText(data),
                uid: change.doc.id,
              })
            }
          })

          setItems(prev => {
            if (prev) {
              return [
                ...prev.filter(it => !deletions.includes(it.uid)),
                ...updates,
              ]
            }

            return updates
          })
        })
    }
  }, [user])

  return items
}

export default useMacOrders
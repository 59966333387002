import React from 'react'
import useFirestoreShopifyOrders from '../../hooks/useFirestoreShopifyOrders'
import { RootContext } from '../../state/RootContext'
import ShopifyOrderRow from './ShopifyOrderRow'
import './ShopifyOrders.scss'
import moment from 'moment'
import { useShopifyGraphQLClient } from '../../hooks/useShopifyOrders'
import { useQuery, Provider } from 'urql'
import TestQuery from '../../queries/shopify/TestQuery'

const List = () => {
  const [result, reexecuteQuery] = useQuery({
    query: TestQuery
  })

  return (
    <div className="List">
      {JSON.stringify(result)}
    </div>
  )
}

const ShopifyOrders = () => {
  const { state } = React.useContext(RootContext)
  const { user } = state
  const shopifyOrders = useFirestoreShopifyOrders(user)
  const { client } = useShopifyGraphQLClient(user)

  return (
    <Provider value={client}>
      <List />

      <div className="ShopifyOrders">
        <h1>Shopify Orders</h1>

        <div className="OrdersContainer">
          {shopifyOrders && shopifyOrders.sort((a, b) => moment(b.created_at).diff(moment(a.created_at))).map(it => (
            <ShopifyOrderRow key={it.uid} {...it} />
          ))}
        </div>
      </div>

    </Provider>
  )
}

export default ShopifyOrders
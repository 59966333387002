import React from 'react'
import './Header.scss'
import Logo from './Logo'
import { NavLink } from 'react-router-dom'

type HeaderProps = {
  onSignOut: () => Promise<void>
}
const Header: React.FC<HeaderProps> = (props) => {
  const { onSignOut } = props

  return (
    <div className="Header">
      <Logo height={40} />
      <NavLink exact to="/orders">Orders</NavLink>
      <NavLink exact to="/shopify">Shopify</NavLink>
      <NavLink exact to="/waveapps">Waveapps</NavLink>
      <NavLink exact to="/flavours">Flavours</NavLink>
      <NavLink exact to="/inventory">Inventory</NavLink>
      <NavLink exact to="/bake-schedule">Bake Schedule</NavLink>
      <NavLink exact to="/account">Account</NavLink>
      <button className="button" type="button" onClick={onSignOut}>Logout</button>
    </div>
  )
}

export default Header
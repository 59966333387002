import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'

export const cloud = firebase.initializeApp({
  apiKey: "AIzaSyC9p7q9bOTpvtxutFfKRZ61XzUxc_w-JzA",
  authDomain: "macaron-bar-manager.firebaseapp.com",
  databaseURL: "https://macaron-bar-manager.firebaseio.com",
  projectId: "macaron-bar-manager",
  storageBucket: "macaron-bar-manager.appspot.com",
  messagingSenderId: "96903800862",
  appId: "1:96903800862:web:075d1d4b106a20e556a60f",
  measurementId: "G-WR6GQYDH7G"
}, 'macaron-bar-manager')

// cloud.analytics()
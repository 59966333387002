import React from 'react'
import './Waveapps.scss'
import { RootContext } from '../../state/RootContext'
import { useWaveGraphQLClient } from '../../hooks/useWaveInvoices'
import { Provider } from 'urql'
import WaveInvoices from './WaveInvoices'
import WaveCustomers from './WaveCustomers'

const Waveapps = () => {
  const { state } = React.useContext(RootContext)
  const { user } = state
  const {
    client,
    businessId,
  } = useWaveGraphQLClient(user)
  const [showCustomers, setShowCustomers] = React.useState(false)

  return (
    <Provider value={client}>
      <div className="Invoices">
        <button className="button" type="button" onClick={() => setShowCustomers(prev => !prev)}>{showCustomers ? 'Hide' : 'Show'} Customers</button>
        {showCustomers && (
          <>
            <h1>Customers</h1>
            <p>Below is a list of customers in Waveapps. Green means it's in sync with the Macaron Bar manager.</p>
            <p>Customer in sync with the Macaron Bar manager can be used to create invoices.</p>
            {businessId && <WaveCustomers businessId={businessId} />}
          </>
        )}
        
        <h1>Invoices</h1>
        {businessId && <WaveInvoices businessId={businessId} />}
      </div>
    </Provider>
  )
}

export default Waveapps
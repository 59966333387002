import React from 'react'
import { WaveInvoice } from '../../hooks/useWaveInvoices'
import './MiniInvoice.scss'

interface MiniInvoiceProps extends WaveInvoice {
}

const MiniInvoice: React.FC<MiniInvoiceProps> = (props) => {
  const { invoiceNumber, dueDate, customer, amountPaid, total, memo, items } = props
  const paymentStatus = React.useMemo(() => {
    if (amountPaid.raw === total.raw) {
      return 'Paid'
    }
    if (amountPaid.raw > 0) {
      return 'PartialPaid'
    }
    return 'Unpaid'
  }, [amountPaid, total])

  return (
    <div className={`MiniInvoice ${paymentStatus}`}>
      <div className="Row">
        <div className="Customer">
          <span>{customer.name}</span>
          <span>{customer.email}</span>
        </div>
        <div className="InvoiceDate">
          <span>{invoiceNumber}</span>
          <span>{dueDate}</span>
        </div>
      </div>
      <div className="LineItems">
        {items.map((it, index) => (
          <div className="LineItem" key={index}>
            <div className="SubTotal">
              <strong>{it.product.name}</strong>
              <span>{it.quantity}</span>
              <span>{it.unitPrice}</span>
            </div>
            <span>{it.description}</span>
          </div>
        ))}
      </div>

      <div className="AmountDue">
        <span>{total.raw/100}</span>
        <span>{amountPaid.raw/100}</span>
      </div>
      
      <div className="Memo">
        {memo}
      </div>
    </div>
  )

}

export default MiniInvoice
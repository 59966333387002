import React from 'react'
import { WeeklyFlavour, generateFilterText, hashWeeklyFlavour, saveToCloud, WEEKLY_FLAVOURS_COLLECTION } from '../../hooks/useWeeklyFlavours'
import { cloud } from '../../state/config'
import TextInput from '../../components/TextInput'
import DatePickerInput from '../../components/DatePickerInput'
import moment from 'moment'

interface WeekyFlavourFormProps extends WeeklyFlavour {
  onClose: () => void
}

const reducer = (prev: WeeklyFlavour, next: any): WeeklyFlavour => {
  const state = { ...prev, ...next }

  return {
    ...state,
    filterText: generateFilterText(state),
  }
}

const WeekyFlavourForm: React.FC<WeekyFlavourFormProps> = (props) => {
  const { onClose: dismissForm, ...flavour } = props
  const [state, dispatch] = React.useReducer(reducer, flavour)
  const { title, description, theme, startAt, endAt } = state
  const newHash = React.useMemo(() => hashWeeklyFlavour(state).hash, [state])

  const onClose = React.useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    if (newHash === state.hash || confirm("You have unsaved changes, are you sure you want to cancel?")) {
      dismissForm()
    }
  }, [dismissForm, state, newHash])

  const onDelete = React.useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    const result = confirm("Are you sure you want to delete?")

    if (result && cloud.auth().currentUser) {
      cloud
        .firestore()
        .collection(WEEKLY_FLAVOURS_COLLECTION)
        .doc(state.uid)
        .delete()
        .then(dismissForm)
    }
  }, [state, dismissForm])

  const onSave = React.useCallback(() => {
    saveToCloud(state)
      .then(dismissForm)
  }, [state, dismissForm])

  return (
    <div className="WeekyFlavourForm">
      <TextInput placeholder="" label="Title" value={title} onChange={val => dispatch({ title: val })} />
      <TextInput textarea placeholder="" label="Description" value={description} onChange={val => dispatch({ description: val })} />
      <TextInput placeholder="" label="Theme" value={theme} onChange={val => dispatch({ theme: val })} />

      <DatePickerInput selectedDate={moment(startAt)} label="Start" onChange={val => dispatch({ startAt: val.toISOString(true), startAtMs: val.valueOf() })} />
      <DatePickerInput selectedDate={moment(endAt)} label="End" onChange={val => dispatch({ endAt: val.toISOString(true), endAtMs: val.valueOf() })} />

      <div className="FormActionGroup">
        <button className="button delete" type="button" onClick={onDelete}>Delete</button>
        <button className="button cancel" type="button" onClick={onClose}>Cancel</button>
        <button className="button success" type="button" onClick={onSave}>Save</button>
      </div>
    </div>
  )

}

export default WeekyFlavourForm
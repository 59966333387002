import React from 'react'
import './WaveInvoices.scss'
import { useWaveGraphQLClient, InvoicesQueryResponse, CustomersQueryResponse } from '../../hooks/useWaveInvoices'
import { useQuery, Provider } from 'urql'
import InvoicesQuery from '../../queries/wave/InvoicesQuery'
import MiniInvoice from './MiniInvoice'

type WaveInvoicesProps = {
  businessId?: string
}

const WaveInvoices: React.FC<WaveInvoicesProps> = (props) => {
  const { businessId } = props
  const [page, setPage] = React.useState(1)
  const [pageSize, setPageSize] = React.useState(50)
  const [result, reexecuteQuery] = useQuery({
    query: InvoicesQuery,
    variables: { 
      id: businessId,
      page, 
      pageSize
    },
  })

  const { data: rawData, fetching, error } = result
  const data: undefined | InvoicesQueryResponse = rawData
  
  if (fetching) return <p>Loading...</p>
  if (error) return <p>Oh no... {error.message}</p>

  const totalPages = data?.business.invoices.pageInfo.totalPages
  const currentPage = data?.business.invoices.pageInfo.currentPage

  return (
    <div className="WaveInvoices">
      {totalPages !== undefined && currentPage !== undefined && (
        <div className="Paginator">
          <button className="button" type="button" onClick={() => setPage(prev => Math.max(1, prev - 1))}>Back</button>
          <span>{currentPage} of {totalPages}</span>
          <button className="button" type="button" onClick={() => setPage(prev => Math.min(totalPages, prev + 1))}>Next</button>
        </div>
      )}

      <div className="InvoicesContainer">
        {data?.business.invoices.edges.map(it => <MiniInvoice key={it.node.id} {...it.node} />)}
      </div>
    </div>
  )
}

export default WaveInvoices
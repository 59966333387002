import React from 'react'
import firebase from 'firebase'
import classNames from 'classnames'
import TextInput from '../../components/TextInput'
import Logo from '../../components/Logo'
import useLocalStorage from '../../hooks/useLocalStorage'
import useDebounceValue from '../../hooks/useDebounceValue'
import SignUp from './SignUp'
import './Login.scss'
import Modal from '../Modal'

type LoginProps = {
  user?: firebase.User
  signIn: (email: string, password: string) => void
  fetching: boolean
  error?: Error
  localStoragePrefix?: string
}

const Login: React.FC<LoginProps> = ({ user, signIn, fetching, error, localStoragePrefix = '' }) => {
  const [email, setEmail] = useLocalStorage(`${localStoragePrefix}email`, '')
  const [password, setPassword] = React.useState('')
  const [debouncedValue] = useDebounceValue(fetching, 500)
  const enabled = !debouncedValue
  const onSignIn = React.useCallback(() => signIn(email, password), [signIn, email, password])
  const noUser = !user
  const hasPassword = password !== ''
  const showForm = React.useMemo(() => (noUser && enabled) || hasPassword, [noUser, enabled, hasPassword])

  const [signup, setSignup] = React.useState(false)
  const onCloseSignup = React.useCallback(() => setSignup(false), [])
  const onOpenSignUp = React.useCallback(() => setSignup(true), [])

  return (
    <form className="Login" onSubmit={e => { e.preventDefault(); onSignIn() }}>
      <Logo height={80} collapseWidth />
      <div style={{ height: '30px' }} />
      {showForm && (
        <div className="form entered">
          <TextInput
            value={email}
            onChange={setEmail}
            enabled={enabled}
            label="Email"
            orientation="vertical"
            type="email"
          />
          <TextInput
            value={password}
            onChange={setPassword}
            enabled={enabled}
            label="Password"
            type="password"
          />
          <div className="ButtonContainer">
            <button type="submit" className={classNames('button wide button', { 'disabled': !enabled })}>Login</button>
            <button type="button" className="button secondary" onClick={onOpenSignUp}>Register</button>
          </div>
        </div>
      )}

      {signup && (
        <Modal onClose={onCloseSignup}>
          <SignUp />
        </Modal>
      )}

      {error && <span className="Error">{error.message}</span>}
    </form>
  )
}

export default Login
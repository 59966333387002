import React from 'react'
import firebase from 'firebase'
import { cloud } from '../state/config'
import { useGenericFirestoreDocumentHook } from './useGenericFirestore'

// const applyFilterText = <T>(data: T): T => {
//   return data
// }

// const applyMigration = <T>(data: T): T => {
//   return data
// }

export interface PersonalNote {
  _fields: string[]
}

const usePersonalNotes = (user?: firebase.User) => {
  const ref = React.useMemo(() => user && cloud.firestore().collection('notes').doc(user.uid), [user])
  return useGenericFirestoreDocumentHook<PersonalNote>(user, ref)
}

export default usePersonalNotes
import React from 'react'
import { ProcessLineItem } from '../../hooks/useMacOrders'


type FlavourRowProps = {
  group: ProcessLineItem[]
  styles: string[] // ('custom' | 'signature' | 'classic')[]
}

const FlavourRow: React.FC<FlavourRowProps> = (props) => {
  const { group, styles } = props

  if (group.filter(it => styles.includes(it.style)).length === 0) {
    return null
  }

  const title = group[0].flavour
  const total = styles.reduce((prev: number, next: string) => {
    const subtotal = group.filter(it => it.style === next).reduce((p, n) => p + n.qty, 0)
    return prev + subtotal
  }, 0)
  const batchCount = title.toLowerCase().includes('vegan') ? 30 : 60
  const batches = total / batchCount
  const totalFromWholeBatch = Math.ceil(batches) * batchCount

  return (
    <div className="Grouped">
      <span className="Flavour">{title}</span>
      {styles.map(style => <StyleRow key={style} group={group} style={style} />)}
      <p><span className="Total">{total} Total</span></p>
      <p><span>{batches.toFixed(1)} Batches of {batchCount} Macs ({total} required, {totalFromWholeBatch} baked)</span></p>
      <p><span className="Extras">{totalFromWholeBatch - total} Extra</span></p>
    </div>
  )
}

const StyleRow: React.FC<{ group: ProcessLineItem[], style: string }> = (props) => {
  const { group, style } = props
  const items = group.filter(it => it.style === style)

  return (
    <p>
      <span className={style}>{items.reduce((prev, next) => prev + next.qty, 0)} {style}</span>
    </p>
  )
}

export default FlavourRow
import React from 'react'
import { cloud } from './state/config'
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom'
import './App.scss'
import Login from './components/Login/Login'
import { RootContext, rootReducer, initialRootState, SIGN_IN, SIGN_OUT, RootStorageKeys } from './state/RootContext'
import useFirebaseAuth from './hooks/useFirebaseAuth'
import Waveapps from './pages/Waveapps/Waveapps'
import Header from './components/Header'
import Account from './pages/Account/Account'
import Orders from './pages/Orders/Orders'
import ShopifyOrders from './pages/ShopifyOrders/ShopifyOrders'
import WeeklyFlavours from './pages/WeeklyFlavours/WeeklyFlavours'
import BakeSchedule from './pages/BakeSchedule/BakeSchedule'
import Inventory from './pages/Inventory/Inventory'
import PrivateRoute from './routing/PrivateRoute'
import PublicOnlyRoute from './routing/PublicOnlyRoute'

const App = () => {
  const [state, dispatch] = React.useReducer(rootReducer, initialRootState)
  const { user } = state
  const { user: authUserSource, firebaseToken, signIn, signOut, fetching, error } = useFirebaseAuth(cloud, 500)
  const authUser = React.useMemo(() => authUserSource, [authUserSource])

  const signOutWithDispatch = React.useCallback(() => {
    return signOut().then(() => dispatch({ type: SIGN_OUT }))
  }, [dispatch, signOut])

  // Auto-Login Handler
  React.useEffect(() => {
    if (authUser && firebaseToken) {
      dispatch({ type: SIGN_IN, user: authUser, firebaseToken })
    }
  }, [dispatch, authUser, firebaseToken])

  return (
    <RootContext.Provider value={{ state, dispatch }}>
      <div className="App">
        <Router>
          {user && <Header onSignOut={signOutWithDispatch} />}

          <Switch>
            <PublicOnlyRoute path="/login">
              <Login user={user} signIn={signIn} fetching={fetching} error={error} />
            </PublicOnlyRoute>

            <PrivateRoute path="/orders">
              <Orders />
            </PrivateRoute>

            <PrivateRoute path="/shopify">
              <ShopifyOrders />
            </PrivateRoute>

            <PrivateRoute path="/waveapps">
              <Waveapps />
            </PrivateRoute>

            <PrivateRoute path="/account">
              <Account />
            </PrivateRoute>

            <PrivateRoute path="/flavours">
              <WeeklyFlavours />
            </PrivateRoute>

            <PrivateRoute path="/bake-schedule">
              <BakeSchedule />
            </PrivateRoute>

            <PrivateRoute path="/inventory">
              <Inventory />
            </PrivateRoute>

            <PrivateRoute exact path="/">
              {/* <Home /> */}
              <Orders />
            </PrivateRoute>
          </Switch>
        </Router>
      </div>
    </RootContext.Provider>
  )
}
export default App
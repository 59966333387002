import React from 'react'
import { cloud } from '../state/config'
import { createClient, Provider, Client } from 'urql'

export type WaveInvoice = {
  id: string
  invoiceNumber: string
  invoiceDate: string
  dueDate: string
  memo: string
  total: {
    raw: number
  }
  amountDue: {
    raw: number
  }
  amountPaid: {
    raw: number
  }
  customer: {
    name: string
    email: string
  }
  items: LineItem[]
}

export type Province = {
  code: string
  name: string
}

export type Country = {
  code: 'AU'
  name: string
  currency: 'AUD'
  nameWithArticle: string
  provinces: Province[]
}

export type Address = {
  addressLine1: string | null
  addressLine2: string | null
  city: string | null
  province: Province | null
  country: Country | null
  postalCode: string | null
}

export type CustomerShippingDetails = {
  name: string | null
  address: Address | null
  phone: string | null
  instructions: string | null
}

export type WaveCustomer = {
  id: string
  name: string
  address: Address | null
  firstName: string | null
  lastName: string | null
  displayId: string | null
  email: string | null
  mobile: string | null
  phone: string | null
  fax: string | null
  tollFree: string | null
  website: string | null
  internalNotes: string | null
  currency: 'AUD' | null
  shippingDetails: CustomerShippingDetails | null
  createdAt: string // yyyy-MM-ddThh:mm:ss.sssZ
  modifiedAt: string // yyyy-MM-ddThh:mm:ss.sssZ
  isArchived: boolean | null
}

type LineItem = {
  unitPrice: string
  quantity: string
  description: string
  product: {
    id: string
    name: string
  }
}

export type InvoicesQueryResponse = {
  business: {
    invoices: {
      pageInfo: {
        currentPage: number
        totalPages: number
      }
      edges: {
        node: WaveInvoice
      }[]
    }  
  }
}

export type CustomersQueryResponse = {
  business: {
    customers: {
      pageInfo: {
        currentPage: number
        totalPages: number
      }
      edges: {
        node: WaveCustomer
      }[]
    }  
  }
}

type WaveSettings = {
  business_id: string
  client_id: string
  client_secret: string
  full_access_token: string
  url: string
}

const useWaveCredentials = (user?: firebase.User) => {
  const [settings, setSettings] = React.useState<WaveSettings>()

  React.useEffect(() => {
    if (user) {
      cloud
        .firestore()
        .collection('secrets')
        .doc('wave')
        .get()
        .then(snap => setSettings(snap.data() as WaveSettings))
    }
  }, [user])
  
  return settings
}

export const useWaveGraphQLClient = (user?: firebase.User) => {
  const settings = useWaveCredentials(user)
  const [client, setClient] = React.useState<Client>(createClient({ url: 'http://localhost' }))

  React.useEffect(() => {
    if (settings) {
      const { full_access_token, url } = settings
      setClient(createClient({
        url,
        fetchOptions: () => ({ headers: { authorization: `Bearer ${full_access_token}` }}),
      }))
    }
  }, [settings])

  return {
    client,
    businessId: settings?.business_id,
  }
}
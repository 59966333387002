import React from 'react'
import {
  Route,
  RouteProps,
} from 'react-router-dom'
import { RootStorageKeys } from '../state/RootContext'

const GeneralRoute: React.FC<RouteProps> = (props) => {
  const { location } = props

  if (typeof location?.pathname === 'string') {
    localStorage.setItem(RootStorageKeys.lastPage, location?.pathname)
  }

  return <Route {...props} />
}

export default GeneralRoute
import React from 'react'
import CalendarDatePicker from './CalendarDatePicker'
import moment, { Moment } from 'moment-timezone'
import TextInput, { TextInputOrientation } from './TextInput'
import ReactDatePicker from 'react-datepicker'

const DatePickerInput: React.FC<{
  selectedDate: Moment
  onChange: (date: Moment) => void
  label?: string
  orientation?: TextInputOrientation
  placeholder?: string
  enabled?: boolean
  showTimeSelect?: boolean
}> = ({ selectedDate, onChange, orientation, label, placeholder, enabled, showTimeSelect }) => {
  const calendarRef = React.useRef<ReactDatePicker | null>(null)

  return (
    <CalendarDatePicker
      showTimeSelect={showTimeSelect}
      reactDatePickerRef={calendarRef}
      selected={selectedDate.toDate()}
      onChange={date => date && !Array.isArray(date) && onChange(moment(date))}
      customInput={
        <div>
          <TextInput
            placeholder={placeholder}
            label={label}
            orientation={orientation}
            onClick={() => calendarRef.current?.setOpen(true)}
            value={selectedDate.format(showTimeSelect ? 'ddd DD/MM/YYYY h:mma' : 'ddd DD/MM/YYYY')}
            enabled={enabled !== false}
            onChange={() => { return }}
          />
        </div>
      }
    />
  )
}

export default DatePickerInput
import React from 'react'
import {
  Route,
  Redirect,
  RouteComponentProps,
  RouteProps,
} from 'react-router-dom'
import { cloud } from '../state/config'
import { RootStorageKeys } from '../state/RootContext'

interface PublicOnlyRouteProps extends RouteProps {
  isUserFetching?: boolean
}

// Redirects the user to home if they're already logged in
const PublicOnlyRoute: React.FC<PublicOnlyRouteProps> = ({ children, isUserFetching, ...props }) => {
  const render = React.useCallback(({ location }: RouteComponentProps<any>) => {
    if (isUserFetching) {
      return <p>Loading..</p>
    }

    if (cloud.auth().currentUser) {
      const lastPage = localStorage.getItem(RootStorageKeys.lastPage)
      return <Redirect to={{ pathname: lastPage || '/', state: { from: location } }} />
    }

    return children
  }, [children, isUserFetching])

  return (
    <Route {...props} render={render} />
  )
}

export default PublicOnlyRoute
import React from 'react'
import { MacOrder, generateFilterText, hashOrder, saveToCloud, MacOrderSource, processLineItems, createBlankOrder } from '../../hooks/useMacOrders'
import moment from 'moment'
import TextInput from '../../components/TextInput'
import DatePickerInput from '../../components/DatePickerInput'
import classnames from 'classnames'
import { cloud } from '../../state/config'
import {
  Link,
  Redirect,
  useParams
} from 'react-router-dom'
import './MacOrderForm.scss'

const reducer = (prev: MacOrder, next: any): MacOrder => {
  const state = {...prev,...next}

  return {
    ...state,
    filterText: generateFilterText(state),
  }
}

interface MacOrderFormProps {
  orders: MacOrder[]
}

const MacOrderForm: React.FC<MacOrderFormProps> = (props) => {
  const { orders } = props
  const { orderId } = useParams() as { orderId?: string }
  const order = React.useMemo(() => orders.find(it => it.uid === orderId) || createBlankOrder(), [orders, orderId])
  const [state, dispatch] = React.useReducer(reducer, order)
  const newHash = React.useMemo(() => hashOrder(state).hash, [state])
  const [navigateTo, setNavigateTo] = React.useState<string>()
  const dismissForm = React.useCallback(() => setNavigateTo('/orders'), [])

  const onClose = React.useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    if (newHash === state.hash || confirm("You have unsaved changes, are you sure you want to cancel?")) {
      dismissForm()
    }
  }, [dismissForm, state, newHash])

  const onDelete = React.useCallback(() => {
    // eslint-disable-next-line no-restricted-globals
    const result = confirm("Are you sure you want to delete?")

    if (result && cloud.auth().currentUser) {
      cloud
        .firestore()
        .collection('mac_orders')
        .doc(state.uid)
        .delete()
        .then(dismissForm)
    }
  }, [state, dismissForm])

  const onSave = React.useCallback(() => {
    saveToCloud(state)
      .then(dismissForm)
  }, [state, dismissForm])

  const {
    customerName,
    customerType,
    customerEmail,
    customerPhone,
    customerAddress,
    orderDescription,
    paymentStatus,
    nextAction,
    invoiceNumber,
    fulfillAt,
    sources,
  } = state

  const setSource = React.useCallback((source: MacOrderSource) => {
    const filtered = sources.filter(it => it !== source)
    if (sources.find(it => it === source)) {
      dispatch({ sources: [...filtered] })
    } else {
      dispatch({ sources: [...filtered, source] })
    }
  }, [sources])

  React.useEffect(() => {
    function keyUp(e: KeyboardEvent) {
      if (e.key === 'Escape') {
        onClose()
      }

      if (e.ctrlKey && e.key === 's') {
        onSave()
        e.preventDefault()
      }
    }
    
    window.addEventListener('keyup', keyUp)
    return () => {
      window.removeEventListener('keyup', keyUp)
    }
  }, [onClose, onSave])

  if (navigateTo) {
    return <Redirect to={navigateTo}/>
  }

  return (
    <div className="MacOrderForm">
      {newHash !== state.hash && <span className="edited">edited</span>}

      <div className="FormActionGroup">
        <button className="button cancel" type="button" onClick={onClose}>Cancel</button>
        <button className="button success" type="button" onClick={onSave}>Save</button>
      </div>

      <div className="CustomerTypeContainer">
        <label>Customer Type</label>
        <ButtonGroup>
          <button type="button" onClick={() => dispatch({ customerType: 'pickup' })} className={classnames('button', { notSelected: customerType !== 'pickup' })}>pickup</button>
          <button type="button" onClick={() => dispatch({ customerType: 'delivery' })} className={classnames('button', { notSelected: customerType !== 'delivery' })}>delivery</button>
          <button type="button" onClick={() => dispatch({ customerType: 'stockist' })} className={classnames('button', { notSelected: customerType !== 'stockist' })}>stockist</button>
        </ButtonGroup>
      </div>

      <div className="SourceContainer">
        <label>Source</label>
        <ButtonGroup>
          <button type="button" onClick={() => setSource('facebook')} className={classnames('button', { notSelected: !sources.includes('facebook') })}>facebook</button>
          <button type="button" onClick={() => setSource('shopify')} className={classnames('button', { notSelected: !sources.includes('shopify') })}>shopify</button>
          <button type="button" onClick={() => setSource('phone')} className={classnames('button', { notSelected: !sources.includes('phone') })}>phone</button>
          <button type="button" onClick={() => setSource('email')} className={classnames('button', { notSelected: !sources.includes('email') })}>email</button>
        </ButtonGroup>
      </div>

      <TextInput className="flex1" placeholder="" label="Customer Name" value={customerName} onChange={val => dispatch({ customerName: val })} />
      
      <DatePickerInput showTimeSelect selectedDate={moment(fulfillAt)} label="Fulfill At" onChange={val => dispatch({ fulfillAt: val.toISOString(true) })} />

      <div className="NextActionContainer">
        <label>Next Action</label>
        <ButtonGroup>
          <button type="button" onClick={() => dispatch({ nextAction: 'follow_up' })} className={classnames('button', { notSelected: nextAction !== 'follow_up' })}>follow up</button>
          <button type="button" onClick={() => dispatch({ nextAction: 'send_invoice' })} className={classnames('button', { notSelected: nextAction !== 'send_invoice' })}>send invoice</button>
          <button type="button" onClick={() => dispatch({ nextAction: 'schedule_fulfillment' })} className={classnames('button', { notSelected: nextAction !== 'schedule_fulfillment' })}>schedule fulfillment</button>
          <button type="button" onClick={() => dispatch({ nextAction: 'schedule_bake' })} className={classnames('button', { notSelected: nextAction !== 'schedule_bake' })}>schedule bake</button>
          <button type="button" onClick={() => dispatch({ nextAction: 'bake' })} className={classnames('button', { notSelected: nextAction !== 'bake' })}>bake</button>
          <button type="button" onClick={() => dispatch({ nextAction: 'pack' })} className={classnames('button', { notSelected: nextAction !== 'pack' })}>pack</button>
          <button type="button" onClick={() => dispatch({ nextAction: 'fulfill' })} className={classnames('button', { notSelected: nextAction !== 'fulfill' })}>fulfill</button>
          <button type="button" onClick={() => dispatch({ nextAction: 'none' })} className={classnames('button', { notSelected: nextAction !== 'none' })}>none</button>
        </ButtonGroup>
      </div>

      <div className="PaymentStatusContainer">
        <label>Payment Status</label>
        <ButtonGroup>
          <button type="button" onClick={() => dispatch({ paymentStatus: 'donation' })} className={classnames('button', { notSelected: paymentStatus !== 'donation' })}>donation</button>
          <button type="button" onClick={() => dispatch({ paymentStatus: 'draft' })} className={classnames('button', { notSelected: paymentStatus !== 'draft' })}>draft</button>
          <button type="button" onClick={() => dispatch({ paymentStatus: 'invoiced' })} className={classnames('button', { notSelected: paymentStatus !== 'invoiced' })}>invoiced</button>
          <button type="button" onClick={() => dispatch({ paymentStatus: 'invoiced_cash' })} className={classnames('button', { notSelected: paymentStatus !== 'invoiced_cash' })}>invoiced_cash</button>
          <button type="button" onClick={() => dispatch({ paymentStatus: 'paid' })} className={classnames('button', { notSelected: paymentStatus !== 'paid' })}>paid</button>
          <button type="button" onClick={() => dispatch({ paymentStatus: 'paid_cash' })} className={classnames('button', { notSelected: paymentStatus !== 'paid_cash' })}>paid_cash</button>
        </ButtonGroup>
      </div>

      <div className="DescriptionContainer">
        <TextInput placeholder="" label="Order Description" textarea value={orderDescription} onChange={val => dispatch({ orderDescription: val })} />
        <div className="Processed">
          <strong>Order (Processed)</strong>
          {processLineItems({ orderDescription } as MacOrder).map((it, index) => <p key={index}>{it}</p>)}
        </div>
      </div>
      <TextInput placeholder="" label="Invoice #" value={invoiceNumber} onChange={val => dispatch({ invoiceNumber: val })} />

      <ButtonGroup>
        <TextInput placeholder="" label="Customer Email" value={customerEmail} onChange={val => dispatch({ customerEmail: val })} />
        <TextInput placeholder="" label="Customer Phone" value={customerPhone} onChange={val => dispatch({ customerPhone: val })} />
        {customerType !== 'pickup' && <TextInput placeholder="" label="Customer Address" value={customerAddress} onChange={val => dispatch({ customerAddress: val })} />}
      </ButtonGroup>

      <div className="FormActionGroup">
        <button className="button delete" type="button" onClick={onDelete}>Delete</button>
      </div>
    </div>
  )
}

const ButtonGroup: React.FC = (props) => {
  return (
    <div className="ButtonGroup">
      {props.children}
    </div>
  )
}

export default MacOrderForm